<template>
  <div class="u-news-page" :class="{ 'u-news-page--is-empty': !isExistsPage }">
    <UFigures
      :items="figures"
      full-width
      position="static"
    >
      <div class="u-news-page__inner">
        <div class="u-news-page__wrapper">
          <div class="u-news-page__filter">
            <div v-if="themesList?.length" class="u-news-page__themes">
              <h2 class="u-news-page__themes-title title-xl tt-uppercase">Популярные темы</h2>
              <FilterTags
                :value="FILTER.sid"
                :list="getThemesList"
                class="u-news-page__themes-tags"
                @input="onChangeTheme"
              />
            </div>
            <UNewsPageFilter
              v-if="!hideFilter"
              :filter="FILTER"
              :regionOptions="regionOptions"
              :activityOptions="activityOptions"
              :initiativeOptions="initiativeOptions"
              :dateOptions="dateOptions"
              :timePeriod="timePeriod"
              :initiativeEnumId="initiativeEnumId"
              @change="onChangeFilter"
            />
          </div>
          <div class="u-news-page__body">
            <UNewsList
              v-if="isExistsPage"
              :newsList="ITEMS"
              class="u-news-page__list"
              ref="newsList"
            />
            <NotFound v-else text="По данным параметрам ничего не найдено" />
            <div
              v-show="HAS_NEXT_PAGE"
              class="u-news-page__action"
            >
              <CustomButton
                class="u-news-page__btn-more"
                size="md"
                theme="primary"
                @click="showNewCards(false)"
              >
                Показать ещё
              </CustomButton>
            </div>
          </div>
        </div>
        <ScrollTopBtn />
      </div>
    </UFigures>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  props: {
    url: {
      type: String,
      required: true
    },
    regionOptions: {
      type: Array,
      default: () => []
    },
    activityOptions: {
      type: Array,
      default: () => []
    },
    initiativeOptions: {
      type: Array,
      default: () => []
    },
    initiativeEnumId: {
      type: Number,
      default: 0
    },
    initiativeCurrent: {
      type: Number,
      default: 0
    },
    dateOptions: {
      type: Array,
      default: () => []
    },
    timePeriod: {
      type: Array,
      default: () => [
        { id: '0', name: 'Все' },
        { id: '1', name: 'Сегодня' }
      ]
    },
    themesList: {
      type: Array,
      default: () => []
    },
    initialNewsItems: {
      type: Array,
      default: () => []
    },
    initNextUrl: {
      type: String,
      default: ""
    },
    initPaginator: {
      type: Object,
      default: () => {}
    },
    hideFilter: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      figures: [
        { 'name':'f-news-page-1','image':'/frontend/assets/img/bg-shapes/circle-left-opacity.svg','animation':'fade-in-left' },
        { 'name':'f-news-page-2','image':'/frontend/assets/img/bg-shapes/circle-group-2-left-opacity.svg','animation':'fade-in-left' },
        { 'name':'f-news-page-3','image':'/frontend/assets/img/bg-shapes/circle-left-opacity.svg','animation':'fade-in-left' },
        { 'name':'f-news-page-lines','image':'/frontend/assets/img/news/bg-lines.png','animation':'fade-in-left' },
      ]
    }
  },

  computed: {
    ...mapGetters('news', ['ITEMS', 'FILTER', 'HAS_NEXT_PAGE', 'IS_LOADING']),

    isExistsPage() {
      return Boolean(this.ITEMS?.length)
    },

    getThemesList() {
      return this.themesList.map(item => {
        const isActive = this.FILTER?.sid?.includes(item.id)

        return {
          ...item,
          isActive: item.isActive || isActive,
        }
      })
    }
  },

  methods: {
    ...mapMutations('news', ['SET_STATE']),
    ...mapActions('news', ['LOAD_ITEMS']),

    async showNewCards(withClear){
      const bottom = this.$refs.newsList.$el.getBoundingClientRect().bottom

      await this.LOAD_ITEMS(withClear)

      window.scrollTo({
        top:  window.scrollY + bottom,
        behavior: 'smooth'
      });
    },

    init() {
      this.SET_STATE({ name: 'paginator', value: this.initPaginator })
      this.SET_STATE({ name: 'url', value: this.url })
      this.SET_STATE({
        name: 'filter',
        value: {
          region: '',
          activity: this.initiativeCurrent ? this.initiativeEnumId : '',
          initiative: this.initiativeCurrent ? this.initiativeCurrent : '',
          year: '',
          period: '0',
          themes: []
        }
      })

      this.LOAD_ITEMS(true)
    },

    onFilter(filter) {
      this.SET_STATE({ name: 'filter', value: filter })

      console.log('filter', filter)

      this.LOAD_ITEMS(true)
    },

    onChangeFilter(filter) {
      this.onFilter(filter)
    },

    onChangeTheme(themes) {
      this.onFilter({ ...this.FILTER, sid: themes })
    }
  },

  created() {
    this.init()
  },

  watch: {
    IS_LOADING(state) {
      this.$root.$emit('setLoading', state)
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.u-news-page';

#{$b} {
  min-height: 600px;
  font-family: $font-family-inter;

  // .u-news-page--is-empty
  &--is-empty {
    .f-news-page-2,
    .f-news-page-3,
    .f-news-page-lines {
      display: none !important;
    }
  }

  // .u-news-page__wrapper
  &__wrapper {
    position: relative;
    z-index: 10;
  }

  // .u-news-page__banner
  &__banner {
    min-height: auto;
  }

  // .u-news-page__filter
  &__filter {
    position: relative;
    padding-top: 64px;
    margin-bottom: 32px;

    @include mobile {
      padding-top: 48px;
      margin-bottom: 48px;
    }
  }

  // .u-news-page__themes
  &__themes {
    margin-bottom: 64px;

    @include mobile {
      margin-bottom: 48px;
    }

    // .u-news-page__themes-title
    &-title {
      font-family: $font-family-main-head;
      margin: 0 0 32px 0;

      @include mobile {
        margin-bottom: 16px;
      }
    }
  }


  // .u-news-page__body
  &__body {
    position: relative;
    padding-bottom: 128px;

    @include mobile {
      padding-bottom: 64px;
    }
  }

  // .u-news-page__action
  &__action {
    margin-top: 32px;

    @include mobile {
      margin-top: 24px;
      text-align: center;
    }
  }
}
</style>
