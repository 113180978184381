<template>
  <div class="u-events">
    <div class="u-events-page__bg-circle-1" />
    <UFigures
      :items="figures"
      fullWidth
      position="static"
    >
      <div class="u-events__wrapper">
        <UEventsFilter
          class="u-events__filter"
          :tab-filters="FILTER_OPTIONS.initialFilters"
          :event-type-options="eventTypeOptions"
          :partners-options="partnersOptions"
          :region-options="regionsOptions"
          :year-options="yearOptions"
          :initiatives-options="initiativesOptions"
          :audience-options="audienceOptions"
          :initial-selected-options="initialSelectedOptions"
          :month-filters="ALLOWED_MONTH"
          :filter="FILTER"
          @change="onFilterChange"
          @region-change="onChangeRegion"
        />
        <div class="u-events__body">
          <UEventsList
            v-if="ITEMS?.length"
            :items="ITEMS"
            ref="eventsList"
            isLinks
          />
          <NotFound v-else text="По данным параметрам ничего не найдено" />
          <div class="u-events__load-more" v-show="HAS_NEXT_PAGE">
            <CustomButton
              theme="primary"
              size="md"
              @click="showNewCards(false)"
            >
              Показать ещё
            </CustomButton>
          </div>
        </div>
      </div>
    </UFigures>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { regionFromOptions } from '@/components/helpers/regionHelper.js'

export default {
  name: 'Events',

  props: {
    url: {
      type: String,
      required: true
    },
    initParams: {
      type: Object,
    },
    initItems: {
      type: Array,
      default: () => []
    },
    initNextUrl: {
      type: String,
      default: ''
    },
    initPaginator: {
      type: Object,
      default: () => {}
    },
  },

  data() {
    return {
      regionFilterChanged: false,
      figures: [
        { 'name':'f-events-page-mob-1','image':'/frontend/assets/img/bg-shapes/circle-group-left-opacity.svg','animation':'fade-in-left' },
        { 'name':'f-events-page-mob-2','image':'/frontend/assets/img/bg-shapes/circle-left-opacity.svg','animation':'fade-in-left' },
        { 'name':'f-events-page-mob-3','image':'/frontend/assets/img/bg-shapes/circle-left-opacity.svg','animation':'fade-in-left' },
        { 'name':'f-events-page-mob-4','image':'/frontend/assets/img/bg-shapes/circle-left-opacity.svg','animation':'fade-in-left' },
        { 'name':'f-events-page-mob-5','image':'/frontend/assets/img/bg-shapes/circle-left-opacity.svg','animation':'fade-in-left' },
      ]
    };
  },

  computed: {
    ...mapGetters('region', ['GET_REGION', 'IS_DETECT_DONE']),
    ...mapGetters('events', ['FILTER_OPTIONS', 'FILTER', 'HAS_NEXT_PAGE', 'ITEMS', 'IS_LOADING', 'ALLOWED_MONTH']),

    initialRegion() {
      return this.IS_DETECT_DONE ? regionFromOptions(this.GET_REGION, this.FILTER_OPTIONS.regions)?.code || '' : ''
    },

    initialSelectedOptions() {
      const region = this.initialRegion

      const result = {
        organizer: this.FILTER?.organizer || '',
        region
      }

      return result
    },

    partnersOptions() {
      return this.sortingAlphabetically(this.FILTER_OPTIONS?.partners);
    },

    regionsOptions() {
      return this.sortingAlphabetically(this.FILTER_OPTIONS?.regions);
    },

    eventTypeOptions() {
      return this.sortingAlphabetically(this.FILTER_OPTIONS?.eventTypeOptions);
    },

    yearOptions() {
      return this.sortingAlphabetically(this.FILTER_OPTIONS?.years);
    },

    initiativesOptions() {
      return this.FILTER_OPTIONS?.initiativesOptions
    },

    audienceOptions() {
      return this.sortingAlphabetically(this.FILTER_OPTIONS?.audienceOptions)
    }
  },
  methods: {
    ...mapMutations('events', ['SET_STATE']),
    ...mapActions('events', ['LOAD_ITEMS']),

    sortingAlphabetically(items) {
      if (!items?.length) {
        return []
      }

      items.sort((a, b) => {
        let fa = a.name.toLowerCase()
        let fb = b.name.toLowerCase()

        if (fa < fb) {
          return -1
        }
        if (fa > fb) {
          return 1
        }

        return 0
      });

      items.sort((item) => {
        if (item.id === '') {
          return -1;
        }
      });

      return items;
    },

    formatDate(date) {
      return moment(date).locale('ru').format('DD MMMM / HH:mm')
    },

    onFilterChange(filter) {
      this.SET_STATE({ name: 'filter', value: filter })

      this.LOAD_ITEMS(true);
    },

    async showNewCards(withClear){
      const bottom = this.$refs.eventsList.$el.getBoundingClientRect().bottom

      await this.LOAD_ITEMS(withClear)

      window.scrollTo({
        top:  window.scrollY + bottom,
        behavior: 'smooth'
      });
    },

    onChangeRegion(state) {
      this.regionFilterChanged = state
    },

    init() {
      this.SET_STATE({ name: 'paginator', value: this.initPaginator })
      this.SET_STATE({ name: 'url', value: this.url })
      this.SET_STATE({
        name: 'filter',
        value: {
          filter: '',
          region: '',
          partners: '',
          type: '',
          year: '',
          initiative: '',
          audience: '',
          months: []
        }
      })
      this.SET_STATE({ name: 'filterOptions', value: this.initParams })

      if (this.IS_DETECT_DONE) {
        this.onFilterChange({ ...this.FILTER, region: this.initialRegion })
      }
    },
  },

  created() {
    this.init()
  },

  watch: {
    IS_LOADING(state) {
      this.$root.$emit('setLoading', state)
    },

    IS_DETECT_DONE(isDone) {
      if (isDone) {
        this.onFilterChange({ ...this.FILTER, region: this.initialRegion })
      }
    },
  }
};
</script>

<style lang="scss">
@import "@/scss/base/u-includes";

$b: '.u-events';
$offsetTop: 47px;

#{$b} {
  display: block;
  padding-bottom: 128px;

  @include tablet {
    padding-bottom: 64px;
  }

  &-section {
    position: relative;
    z-index: 4;
    padding-top: 0;
    padding-bottom: 129px;

    @include mobile {
      padding-bottom: 64px;
    }

  }

  // .u-events__wrapper
  &__wrapper {
    position: relative;
    z-index: 10;
  }

  &__filter {
    transform: translateY(-$offsetTop);
    margin-bottom: -$offsetTop;
  }

  &__body {
    margin-top: 40px;

    @include mobile {
      margin-top: 24px;
    }
  }

  &__load-more {
    margin-top: 32px;

    @include tablet {
      text-align: center;
    }
  }
}
</style>
