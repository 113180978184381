<template>
  <div class="u-news-page-filter">
    <div class="u-news-page-filter__bottom">
      <div v-if="timePeriod" class="u-news-page-filter__tabs">
        <ul class="u-news-page-filter__tab-list">
          <li
            v-for="item in timePeriod"
            class="u-news-page-filter__tab"
            :key="item.id"
            :class="{'u-news-page-filter__tab--active': item.id == filter.period }"
            @click="changeFilterItem('period', item.id)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="u-news-page-filter__select-group">
        <div v-if="dateOptions.length" class="u-news-page-filter__select-date u-select text-md tt-uppercase">
          <v-select
            :value="filter.year"
            :clearable="false"
            :searchable="true"
            label="name"
            :components="{ OpenIndicator }"
            :reduce="option => option.id"
            :options="dateOptions"
            @input="(v) => changeFilterItem('year', v)"
          >
            <template #no-options>
              Не найдено
            </template>
          </v-select>
        </div>
        <div v-if="activityOptions.length" class="u-news-page-filter__select-activity u-select text-md tt-uppercase">
          <v-select
            :value="filter.activity"
            :clearable="false"
            :searchable="true"
            label="name"
            :components="{ OpenIndicator }"
            :reduce="option => option.id"
            :options="activityOptions"
            @input="(v) => changeFilterItem('activity', v)"
          >
            <template #no-options>
              Не найдено
            </template>
          </v-select>
        </div>
        <div
          v-if="initiativeOptions.length && showInitiativeSelect"
          class="u-news-page-filter__select-activity u-select text-md tt-uppercase"
        >
          <v-select
            :value="filter.initiative"
            :clearable="false"
            :searchable="true"
            label="name"
            :components="{ OpenIndicator }"
            :reduce="option => option.id"
            :options="initiativeOptions"
            @input="(v) => changeFilterItem('initiative', v)"
          >
            <template #no-options>
              Не найдено
            </template>
          </v-select>
        </div>
        <div class="u-news-page-filter__select-region u-select text-md tt-uppercase" v-if="regionOptions.length">
          <v-select
            :value="filter.region"
            :clearable="false"
            :searchable="true"
            label="name"
            :components="{ OpenIndicator }"
            :reduce="option => option.code"
            :options="regionOptions"
            @input="(v) => changeFilterItem('region', v)"
          >
            <template #no-options>
              Не найдено
            </template>
          </v-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },

  props: {
    regionOptions: {
      type: Array,
      default: () => []
    },
    activityOptions: {
      type: Array,
      default: () => []
    },
    initiativeOptions: {
      type: Array,
      default: () => []
    },
    dateOptions: {
      type: Array,
      default: () => []
    },
    timePeriod: {
      type: Array,
      default: () => []
    },
    filter: {
      type: Object,
      default: () => ({})
    },
    initiativeEnumId: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      OpenIndicator: {
        render: createElement => createElement('span')
      },
    }
  },

  computed: {
    showInitiativeSelect() {
      return this.filter.activity == this.initiativeEnumId
    }
  },

  methods: {
    changeFilterItem(name, value) {
      let filter = { ...this.filter }

      if (name === 'activity' && !(value == this.initiativeEnumId)) {
        filter.initiative = ''
      }

      this.$emit('change', { ...filter, [name]: value })
    }
  }

}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.u-news-page-filter';

#{$b} {
  // .u-news-page-filter__bottom
  &__bottom {
    display: flex;
    justify-content: space-between;

    @include tablet {
      display: block;
    }
  }

  // .u-news-page-filter__tab-list
  &__tab-list {
    height: 40px;
    display: inline-flex;
    align-items: center;
    text-align: center;
    border: 1px solid $color-base;
    border-radius: 20px;
    overflow: hidden;
  }

  // .u-news-page-filter__tab
  &__tab {
    height: 100%;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    line-height: 1;
    color:$color-base;
    padding: 4px 20px;
    user-select: none;
    cursor: pointer;
    transition: $transtion-default;

    &:hover {
      @include mobile-min {
        &:not(#{$b}__tab--active) {
          background-color: rgba($color-base, 0.1);
        }
      }
    }

    // .u-news-page-filter__tab--active
    &--active {
      background-color: $color-base;
      color: $white-true;
    }
  }

  // .u-news-page-filter__select-group
  &__select-group {
    display: flex;

    @include tablet {
      margin-top: 48px;
      justify-content: space-between;
    }

    @include mobile {
      display: block;
    }
  }

  // .u-news-page-filter__select-date
  &__select-date {
    width: 192px;
    margin-right: 16px;

    @include low-desktop {
      width: 150px;
    }

    @include tablet {
      width: calc((100% / 3) - 10px);
      margin: 0;
    }

    @include mobile {
      width: 100%;
      margin-bottom: 32px;
    }
  }

  // .u-news-page-filter__select-activity
  &__select-activity {
    width: 296px;
    margin-right: 16px;

    @include low-desktop {
      width: 240px;
    }

    @include tablet {
      width: calc((100% / 3) - 10px);
      margin: 0;
    }

    @include mobile {
      width: 100%;
      margin-bottom: 32px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  // .u-news-page-filter__select-region
  &__select-region {
    width: 400px;

    @include low-desktop {
      width: 240px;
    }

    @include tablet {
      width: calc((100% / 3) - 10px);
    }

    @include mobile {
      width: 100%;
    }
  }
}
</style>
