<template>
  <div class="tour-block">
    <div class="tour-block__top" :class="{'tour-block__top--with-img': tourData.image}">
      <div v-if="tourData.image" class="tour-block__preview">
        <img
          :src="tourData.image"
          :alt="tourData.title"
          class="tour-block__preview-img"
        >
      </div>
      <div class="tour-block__info">
        <div class="tour-block__info-place tt-uppercase tour-block__info-item">
          {{ tourData.location }}
        </div>
        <div class="tour-block__info-line tour-block__info-item">
          <time class="tour-block__info-date tt-uppercase">
            {{ formattedDateTime }}
          </time>
          <div v-if="!tourData.isPast && tourData.slots_left" class="tour-block__info-slots tt-uppercase">
            Осталось
            <span class="tour-block__slots">
              {{ tourData.slots_left }}
            </span>
            {{ declinedSlotsLabel }}
          </div>
        </div>
        <div v-if="tourData.address" class="tour-block__info-address tour-block__info-item">
          {{ tourData.address }}
        </div>
        <p class="tour-block__info-description tour-block__info-item fw-400" v-html="getText" />
      </div>
    </div>
    <template v-if="withForm">
      <div class="tour-block__notice">
        <p class="tour-block__notice-text">
          Важно: если Вы планируете зарегистрировать других участников, которые пойдут с Вами на мероприятие,
          необходимо заполнить отдельные анкеты для всех участников
        </p>
      </div>
      <TourForm
        v-if="!isVisibleSuccessBlock"
        :categoryOptions="categoryOptions"
        :submitUrl="submitUrl"
        :decreaseSlotsCardHandler="decreaseSlotsCardHandler"
        :excursionId="tourData.id"
        :requisites="tourData.requisites"
        :yearLimit="{ min: tourData.min_years, max: tourData.max_years }"
        @success="setStateVisibleSuccessBlock(true)"
      />
      <TourFormSuccess v-else />
    </template>

    <div v-if="!tourData?.slots_left && !tourData.isPast" class="tour-block__notice">
      <p class="tour-block__notice-text">
        Регистрация на мероприятие завершена.
        <br>
        Запишитесь на другие экскурсии или лекции на <a href="/">странице</a> акции.
      </p>
    </div>

    <div v-if="tourData.isPast" class="tour-block__notice">
      <p class="tour-block__notice-text">
        Мероприятие проведено.
        <br>
        Запишитесь на другие экскурсии или лекции на <a href="/">странице</a> акции.
      </p>
    </div>
  </div>
</template>

<script>
import { declineNumeral } from '@/components/helpers/formatHelper.js'

export default {
  props: {
    categoryOptions: {
      type: Array,
      default: () => []
    },
    submitUrl: {
      type: String,
      default: ''
    },
    tourData: {
      type: Object,
      required: true
    },
    decreaseSlotsCardHandler: {
      type: Function,
    },
  },

  data() {
    return {
      isVisibleSuccessBlock: false
    }
  },

  computed: {
    getText() {
      return this.tourData.detailText || this.tourData.text
    },
    declinedSlotsLabel() {
      return declineNumeral('slots', this.tourData?.slots_left || 0, {
        'slots': {
          'accusative_singular': 'место',
          'genitive_plural': 'мест',
          'nominative_plural': 'места',
        }
      })
    },
    formattedDateTime() {
      let list = [];
      if (this.tourData.date) {
        list.push(this.tourData.date);
      }
      if (this.tourData.time) {
        list.push(this.tourData.time);
      }
      return list.join(' | ');
    },
    withForm() {
      return this.tourData?.slots_left && !this.tourData.isPast
    }
  },

  methods: {
    setStateVisibleSuccessBlock(state) {
      this.isVisibleSuccessBlock = state
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.tour-block';

#{$b} {
  font-family: $font-family-inter;
  color: $black-true;
  font-size: 16px;
  line-height: 130%;

  // .tour-block__top
  &__top {
    width: 100%;
    margin-bottom: 32px;

    // .tour-block__top--with-img
    &--with-img {
      display: grid;
      grid-template-columns: 493px auto;
      gap: 32px;

      @include low-desktop {
        grid-template-columns: 393px auto;
      }

      @include tablet {
        grid-template-columns: 340px auto;
        gap: 16px;
      }

      @include mobile {
        display: block;
      }
    }
  }

  // .tour-block__preview
  &__preview {
    align-self: flex-start;
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $gradient-img-filter;
      pointer-events: none;
      touch-action: none;
    }

    @include mobile {
      margin-bottom: 32px;
    }

    // .tour-block__preview-img
    &-img {
      width: 100%;
      height: auto;
      border-radius: 8px;
    }
  }

  // .tour-block__info
  &__info {

    // .tour-block__info-line
    &-line {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, auto);
      gap: 20px;
      align-items: center;
      justify-content: space-between;

      @include mobile {
        display: block;
      }
    }

    // .tour-block__info-item
    &-item {
      font-weight: 500;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 24px;

        @include mobile {
          margin-bottom: 32px;
        }
      }
    }

    // .tour-block__info-date
    &-date {
      display: inline-block;

      @include mobile {
        margin-bottom: 16px;
      }
    }
  }

  // .tour-block__slots
  &__slots {
    display: inline-block;
    background: $color-base;
    color: $white-true;
    border-radius: 12px;
    padding: 0 12px;

    @include mobile {
      padding: 0 8px;
    }
  }

  // .tour-block__notice
  &__notice {
    color: $white-true;
    font-weight: 500;
    padding: 32px;
    border-radius: 16px;
    background-image: url('/frontend/assets/img/excursions/circle-group-line.svg');
    background-color: $color-base-origin;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 32px;
    overflow: hidden;

    @include mobile {
      padding: 16px;
      background-image: url('/frontend/assets/img/excursions/circle-group-line-mob.svg');
    }

    &-text {
      margin: 0;
    }

    a:not([class]) {
      color: inherit;
    }
  }
}
</style>
