// удаляет все повторяющиеся символы кроме одного
export function deleteExceptFirst(str, symbol = '.') {
  const firstSymbolIndex = str.indexOf(symbol)

  if (firstSymbolIndex === -1) {
    return str
  }

  const beforeFirstSymbol = str.slice(0, firstSymbolIndex + 1)
  const afterFirstSymbol = str.slice(firstSymbolIndex + 1)
  const removedSymbols = afterFirstSymbol.replaceAll(symbol, '')

  return beforeFirstSymbol.concat(removedSymbols)
}

// возвращает форматированную строку для инпута чисел
export function getFormattedValue(value) {
  if (String(value) === '0') {
    return '0'
  }

  const { int, fraction } = getParsedValue(value)

  const numberWithClearDots = deleteExceptFirst(int.replace(/,/g, '.'), '.')
  const numberValue = Number(numberWithClearDots.replace(/[^0-9.]/g, ''))

  return (numberValue || numberValue === 0) ?
    numberValue.toLocaleString('ru-RU').concat(fraction)
    : ''
}

// разбивает число на дробную и целую часть для инпута чисел
export function getParsedValue(value) {
  const val = deleteExceptFirst(
    String(value).trim().replace(/\./g, ',').replace(/[^0-9,]/g, ''),
    ','
  );

  const separatorSymbol = ','
  let intPart = ''
  let fractionPart = ''

  const intPartIndex = val.indexOf(separatorSymbol)

  if (intPartIndex !== -1) {
    intPart = val.slice(0, intPartIndex)
    fractionPart = val.slice(intPartIndex)
  } else {
    intPart = val
  }

  return { int: intPart, fraction: fractionPart }
}

export function isEmpty(value) {
  return typeof value === 'string' || Array.isArray(value)
    ? !value?.length
    : typeof value === 'object'
      ? !Object.keys(value).some(Boolean)
      :!value
}

/**
 *
 * @param { string } word_type
 * @param { number } number
 * @param { Object } declensionData
 *  { [key: word_type]: {
 *      [key: accusative_singular | nominative_plural | genitive_plural]: string;
 *    }
 *  }
 * @returns
 */
export function declineNumeral(word_type, number, declensionData) {
  let number_string = number.toString();
  let last_id = number_string.length - 1;
  let last_symbol = number_string[last_id];
  let last_but_one_symbol = number_string[last_id - 1];
  let word_case;
  if (last_but_one_symbol == '1') { //10-19, 110-119 etc.
    word_case = 'genitive_plural';
  } else {
      switch (last_symbol) {
        case '1':
          word_case = 'accusative_singular';
          break;
        case '2':
        case '3':
        case '4':
          word_case = 'nominative_plural';
          break;
        default:
          word_case = 'genitive_plural';
          break;
      }
  }
  return declensionData[word_type][word_case];
}
