import axios from 'axios'
import { isEmpty } from '@/components/helpers/formatHelper.js'
import { REGION_MONTH_OPTIONS } from '@/components/helpers/consts.js'

export default {
  namespaced: true,
  state: {
    items: [],
    paginator: {},
    filter: {
      filter: '',
      region: '',
      partners: '',
      type: '',
      year: '',
      initiative: '',
      audience: '',
      months: []
    },
    isLoading: false,
    url: '',
    filterOptions: {},
    monthOptions: REGION_MONTH_OPTIONS
  },

  getters: {
    ITEMS(state) {
      return state.items
    },

    FILTER(state) {
      return state.filter
    },

    PAGEN_NAME(state) {
      return `PAGEN_${state.paginator?.NavNum}`
    },

    NEXT_PAGE_NUMBER(state) {
      return Math.min(state.paginator?.NavPageNomer + 1, state.paginator?.NavPageCount)
    },

    HAS_NEXT_PAGE(state) {
      return state.paginator?.NavPageNomer < state.paginator?.NavPageCount
    },

    API_FILTER_PARAMS(state, getters) {
      let params = {}

      for (let key in state.filter) {
        const value = state.filter[key]

        if (!isEmpty(value)) {
          params[key] = value

          if (key === 'months') {
            const months = value?.filter(month => getters.ALLOWED_MONTH?.map(item => item.id).includes(month))
            params[key] = months
          }
        }
      }

      return params
    },

    IS_LOADING(state) {
      return state.isLoading
    },

    FILTER_OPTIONS(state) {
      return state.filterOptions
    },

    ALLOWED_MONTH(state) {
      return state.monthOptions.filter(option => {
        if (!option.id) {
          return true;
        }

        let currentFilter = state.filter?.filter;
        let currentMonth = new Date().getMonth() + 1;
        let showMonth = true;

        switch (currentFilter) {
          case 'upcoming':
            showMonth = currentMonth <= option.id;
            break;
          case 'past':
            showMonth = currentMonth >= option.id;
            break;
        }

        return showMonth;
      })
    }
  },

  mutations: {
    SET_STATE(state, { name, value }) {
      if (!Object.hasOwn(state, name)) {
        return console.warn(`store:news | SET_STATE - свойство ${name} не объявлено в state экземпляре`)
      }

      state[name] = value
    }
  },

  actions: {
    async LOAD_ITEMS({ state, getters, commit }, withClear = false) {
      try {
        if (getters.IS_LOADING) {
          return
        }

        commit('SET_STATE', { name: 'isLoading', value: true })
        const page = withClear ? 1 : getters.NEXT_PAGE_NUMBER

        const options = {
          method: 'GET',
          url: state.url,
          params: {
            AJAX: 'Y',
            [getters.PAGEN_NAME]: page,
            ...getters.API_FILTER_PARAMS
          }
        }

        const response = await axios(options)

        if (response?.data) {
          const items = withClear ? response.data.ITEMS : [ ...state.items, ...response.data.ITEMS ]
          commit('SET_STATE', { name: 'items', value: items })
          commit('SET_STATE', { name: 'paginator', value: response.data.PAGINATOR })

          const filterOptions = response.data.FILTER

          if (filterOptions) {
            let filter = { ...state.filterOptions }

            for (let key in filterOptions) {
              const value = filterOptions[key]

              filter[key] = value
            }

            if (state.filter.organizer && !state.filterOptions?.partners?.find(option => option.id === state.filter.organizer)) {
              filter.organizer = ''
            }

            commit('SET_STATE', { name: 'filterOptions', value: filter })
          }
        }
      } catch(e) {
        throw new Error(`store:events | LOAD_ITEMS - ${e}`)
      } finally {
        commit('SET_STATE', { name: 'isLoading', value: false })
      }
    }
  }
}
