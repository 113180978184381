import axios from 'axios'
import { isEmpty } from '@/components/helpers/formatHelper.js'

export default {
  namespaced: true,
  state: {
    items: [],
    paginator: {},
    filter: {},
    isLoading: false,
    url: ''
  },

  getters: {
    ITEMS(state) {
      return state.items
    },

    FILTER(state) {
      return state.filter
    },

    PAGEN_NAME(state) {
      return `PAGEN_${state.paginator?.NavNum}`
    },

    NEXT_PAGE_NUMBER(state) {
      return Math.min(state.paginator?.NavPageNomer + 1, state.paginator?.NavPageCount)
    },

    HAS_NEXT_PAGE(state) {
      return state.paginator?.NavPageNomer < state.paginator?.NavPageCount
    },

    API_FILTER_PARAMS(state) {
      let params = {}

      for (let key in state.filter) {
        const value = state.filter[key]

        if (!isEmpty(value)) {
          params[key] = value
        }
      }

      return params
    },

    IS_LOADING(state) {
      return state.isLoading
    },
  },

  mutations: {
    SET_STATE(state, { name, value }) {
      if (!Object.hasOwn(state, name)) {
        return console.warn(`store:news | SET_STATE - свойство ${name} не объявлено в state экземпляре`)
      }

      state[name] = value
    }
  },

  actions: {
    async LOAD_ITEMS({ state, getters, commit }, withClear = false) {
      try {
        if (getters.IS_LOADING) {
          return
        }

        commit('SET_STATE', { name: 'isLoading', value: true })
        const page = withClear ? 1 : getters.NEXT_PAGE_NUMBER

        const options = {
          method: 'GET',
          url: state.url,
          params: {
            AJAX: 'Y',
            [getters.PAGEN_NAME]: page,
            ...getters.API_FILTER_PARAMS
          }
        }

        const response = await axios(options)

        if (response?.data) {
          const items = withClear ? response.data.ITEMS : [ ...state.items, ...response.data.ITEMS ]
          commit('SET_STATE', { name: 'items', value: items })
          commit('SET_STATE', { name: 'paginator', value: response.data.PAGINATOR })
        }
      } catch(e) {
        throw new Error(`store:news | LOAD_ITEMS - ${e}`)
      } finally {
        commit('SET_STATE', { name: 'isLoading', value: false })
      }
    }
  }
}
