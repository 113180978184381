<template>
  <a
    class="i-excursion-card"
    :class="{
      'i-excursion-card--no-image': !data.image,
      'i-excursion-card--group-is-full': groupIsFull,
      'i-excursion-card--is-past': data.isPast,
    }"
    :href="data.url"
    @click="onClickCard"
  >
    <div class="i-excursion-card__header">
      <div
        v-if="data.image"
        class="i-excursion-card__img"
        :style="`background-image:linear-gradient(to bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0)), url('${data.image}')`"
      />
    </div>
    <div class="i-excursion-card__body">
      <div class="i-excursion-card__data-wrapper i-excursion-card__top-line">
        <div
          v-if="Boolean(data.region)"
          class="i-excursion-card__region tt-uppercase"
        >
          {{ data.region }}
        </div>
        <div
          v-if="groupIsFull"
          class="i-excursion-card__group-is-full tt-uppercase"
        >
          Группа набрана
        </div>
        <div v-if="data.dateYear && data.isPast" class="i-excursion-card__year">
          {{ data.dateYear }}
        </div>
      </div>
      <div class="i-excursion-card__data-wrapper">
        <div
          v-if="Boolean(data.date) || Boolean(data.time)"
          class="i-excursion-card__time tt-uppercase"
        >
          {{ formatTime(data.date, data.time) }}
        </div>
        <div
          v-if="!data.isPast"
          class="i-excursion-card__slots tt-uppercase"
        >
          Осталось <strong>{{ slotsLeft }}</strong> {{ declinedSlotsLabel }}
        </div>
      </div>
      <div
        v-if="Boolean(data.partners) && Boolean(data.partners.length)"
        class="i-excursion-card__partners"
      >
        <img
          v-for="(partner, i) in data.partners"
          :key="`i-excursion-card__partner-logo-${i}-${data.id}`"
          class="i-excursion-card__partner-logo"
          :src="partner"
          :alt="data.title"
        >
      </div>
      <h3
        class="i-excursion-card__title tt-uppercase"
        v-html="data.title"
      />
      <p
        v-if="Boolean(data.text)"
        class="i-excursion-card__text"
        v-html="data.text"
      />
      <div v-if="!data.isPast" class="i-excursion-card__actions">
        <CustomButton class="i-excursion-card__btn">
          {{ groupIsFull ? 'Запись закрыта' : 'Записаться' }}
        </CustomButton>
      </div>
    </div>
  </a>
</template>

<script>
import { declineNumeral } from '@/components/helpers/formatHelper.js'

export default {
  inject: ['submitUrl', 'categoryOptions', 'ymHandler'],

  props: {
    data: {
      type: Object,
      require: true,
      default: () => ({ slots_left: 0 })
    },
  },

  data() {
    return {
      slotsLeft: this.data?.slots_left || 0
    }
  },

  computed: {
    declinedSlotsLabel() {
      return declineNumeral('slots', this.slotsLeft, {
        'slots': {
          'accusative_singular': 'место',
          'genitive_plural': 'мест',
          'nominative_plural': 'места',
        }
      })
    },
    groupIsFull() {
      return !this.slotsLeft
    }
  },

  methods: {
    formatTime(date, time) {
      let list = [];
      if (date) {
        list.push(date);
      }
      if (time) {
        list.push(time);
      }
      return list.join(' | ');
    },

    onClickCard() {
      this.ymHandler({ code: '95339628', type: 'reachGoal', operation: 'click_signUp' })
    },

    decreaseNumberOfSlots() {
      const slotsLeft = this.slotsLeft - 1

      this.slotsLeft = slotsLeft < 0 ? 0 : slotsLeft
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.i-excursion-card';

$headerHeight: 289px;

#{$b} {
  position: relative;
  height: 100%;
  font-family: $font-family-inter;
  cursor: pointer;
  color: $white-true;
  text-decoration: none;
  display: block;
  transition: $transtion-default;
  border-radius: 16px;

  // .i-excursion-card--no-image
  &--no-image {
    background-color: $color-base;
  }

  // .i-excursion-card--group-is-full
  &--group-is-full {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba($black-true, 0.7);
      border-radius: 16px;
    }
  }

  @include mobile {
    background-color: $color-base;
  }

  &:hover {
    @include mobile-min {
      transform: scale(1.01);
    }
  }

  // .i-excursion-card__header
  &__header {
    height: $headerHeight;
    overflow: hidden;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;

    @include mobile-min {
      transform: translateY(1px);
    }
    @include mobile {
      width: 100%;
      height: auto;
      aspect-ratio: 376/240;
    }
    #{$b}:not(#{$b}--no-image) & {
      @include mobile {
        overflow: unset;
      }
    }
  }

  // .i-excursion-card__img
  &__img {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @include mobile {
      border-radius: 16px;
    }
  }

  // .i-excursion-card__body
  &__body {
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    overflow: hidden;

    #{$b}--no-image & {
      background-color: transparent;
    }

    @include mobile-min {
      background-color: $color-base;
      height: calc(100% - $headerHeight);
    }
  }

  &__region {
    letter-spacing: 1.8px;
    margin-right: 24px;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 4px 0;

    @include mobile-md {
      width: 100%;
      padding: 0;
      margin: 0;
    }
  }

  &__group-is-full {
    display: flex;
    align-items: center;
    height: 32px;
    font-family: $font-family-main-head;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    background: $color-base;
    border-radius: 16px;
    padding: 1px 8px 0;
    z-index: 1;
    margin-left: auto;

    @include mobile-md {
      margin-left: 0;
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }

  &__top-line {
    position: absolute;
    left: 24px;
    top: 18px;
    width: calc(100% - 48px);
    height: 32px;
    align-items: center;

    @include mobile {
      top: 24px;
    }
  }

  &__data-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  // .i-excursion-card__time
  &__time {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.1em;
    margin-bottom: 16px;
    margin-right: 25px;
    flex-grow: 1;
    flex-shrink: 0;

    @include mobile {
      margin-bottom: 24px;
    }

    @include mobile-md {
      width: 100%;
    }
  }

  // .i-excursion-card__slots
  &__slots {
    font-size: 16px;
    font-weight: 500;
    flex-shrink: 0;
    flex-grow: 0;
    letter-spacing: 1.65px;
    line-height: 130%;
    margin-bottom: 23px;
    strong {
      background: $white-true;
      color: $color-primary;
      border-radius: 10px;
      padding: 0 6px 0 8px;
    }
  }

  // .i-excursion-card__partners
  &__partners {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include mobile {
      margin-bottom: 8px;
    }
  }

  // .i-excursion-card__partner-logo
  &__partner-logo {
    height: 58px;
    width: auto;
    margin-bottom: 18px;

    &:not(:last-child) {
      margin-right: 25px;
    }

    @include mobile {
      margin-bottom: 16px;
    }
  }

  // .i-excursion-card__title
  &__title {
    font-family: $font-family-main-head;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 100%;
    margin: 0 0 16px 0;
    width: 100%;
  }

  // .i-excursion-card__text
  &__text {
    flex-grow: 1;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    margin: 0 0 24px 0;
    width: 100%;
  }

  // .i-excursion-card__actions
  &__actions {
    margin-top: auto;
    text-align: right;
    margin-left: auto;
  }

  &__btn {
    font-weight: 400;

    #{$b}--group-is-full & {
      text-decoration: line-through;
    }
  }

  &--group-is-full {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $white-true;
      opacity: .7;
      border-radius: 16px;
    }
  }
}

</style>
