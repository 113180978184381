<template>
  <div class="u-events-filter">
    <div class="u-events-filter__top">
      <div class="u-events-filter__overflow" v-dragscroll.x:nochilddrag>
        <ul class="u-events-filter__list">
          <li v-for="(item, i) in tabFilters" :key="i">
            <div
              class="u-events-filter__item"
              :class="{ 'is-active': filter.filter === item.filter || (item.filter === 'all' && !filter.filter) }"
              @click.prevent="onChangeFilterItem('filter', item.filter)"
            >
              {{ item.name }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="u-events-filter__bottom">
      <ul class="u-events-filter__dropdowns">
        <li class="u-events-filter__dropdown-item">
          <div class="u-events-filter__dropdown">
            <div class="u-select">
              <v-select
                :value="filter.region"
                :clearable="false"
                :searchable="true"
                label="name"
                :components="{ OpenIndicator }"
                :reduce="option => option.code"
                :options="regionOptions"
                @input="(v) =>  onChangeFilterItem('region', v)"
              >
                <template #no-options>
                  Не найдено
                </template>
              </v-select>
            </div>
          </div>
        </li>
        <li class="u-events-filter__dropdown-item">
          <div class="u-events-filter__dropdown">
            <div class="u-select">
              <v-select
                :value="filter.partners"
                :clearable="false"
                :searchable="true"
                label="name"
                :components="{ OpenIndicator }"
                :reduce="option => option.id"
                :options="partnersOptions"
                @input="(v) =>  onChangeFilterItem('partners', v)"
              >
                <template #no-options>
                  Не найдено
                </template>
              </v-select>
            </div>
          </div>
        </li>
        <li class="u-events-filter__dropdown-item" >
          <div class="u-events-filter__dropdown">
            <div class="u-select">
              <v-select
                :value="filter.type"
                :clearable="false"
                :searchable="true"
                label="name"
                :components="{ OpenIndicator }"
                :reduce="option => option.id"
                :options="eventTypeOptions"
                @input="(v) =>  onChangeFilterItem('type', v)"
              >
                <template #no-options>
                  Не найдено
                </template>
              </v-select>
            </div>
          </div>
        </li>
        <li class="u-events-filter__dropdown-item" >
          <div class="u-events-filter__dropdown">
            <div class="u-select">
              <v-select
                :value="filter.initiative"
                :clearable="false"
                :searchable="true"
                label="name"
                :components="{ OpenIndicator }"
                :reduce="option => option.id"
                :options="initiativesOptions"
                @input="(v) =>  onChangeFilterItem('initiative', v)"
              >
                <template #no-options>
                  Не найдено
                </template>
              </v-select>
            </div>
          </div>
        </li>
        <li class="u-events-filter__dropdown-item">
          <div class="u-events-filter__dropdown">
            <div class="u-select">
              <v-select
                :value="filter.audience"
                :clearable="false"
                :searchable="true"
                label="name"
                :components="{ OpenIndicator }"
                :reduce="option => option.id"
                :options="audienceOptions"
                @input="(v) =>  onChangeFilterItem('audience', v)"
              >
                <template #no-options>
                  Не найдено
                </template>
              </v-select>
            </div>
          </div>
        </li>
        <li class="u-events-filter__dropdown-item">
          <div class="u-events-filter__dropdown">
            <div class="u-select">
              <v-select
                :value="filter.year"
                :clearable="false"
                :searchable="true"
                label="name"
                :components="{ OpenIndicator }"
                :reduce="option => option.id"
                :options="yearOptions"
                @input="(v) =>  onChangeFilterItem('year', v)"
              >
                <template #no-options>
                  Не найдено
                </template>
              </v-select>
            </div>
          </div>
        </li>
      </ul>
      <ul class="u-events-filter__month-list">
        <li v-for="(item, i) in monthFilters" :key="`u-events-filter__month-item-${item.id}`">
          <div
            class="u-events-filter__month-item text-md tt-uppercase"
            :class="{ 'is-active': isActiveMonth(item.id) }"
            @click.prevent="toggleMonthFilter(item.id)"
          >
            <span>{{ item.name }}</span>
            <InlineSvg v-if="isActiveMonth(item.id) && i > 0" src="close" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  name: 'EventsFilter',

  components: { vSelect },

  props: {
    regionOptions: {
      type: Array,
      default: () => []
    },
    partnersOptions: {
      type: Array,
      default: () => []
    },
    eventTypeOptions: {
      type: Array,
      default: () => []
    },
    yearOptions: {
      type: Array,
      default: () => []
    },
    audienceOptions: {
      type: Array,
      default: () => []
    },
    initiativesOptions: {
      type: Array,
      default: () => []
    },
    tabFilters: {
      type: Array,
      default: () => []
    },
    monthFilters: {
      type: Array,
      default: () => []
    },
    initialSelectedOptions: {
      type: Object,
      default: () => ({})
    },
    filter: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      OpenIndicator: {
        render: createElement => createElement('span')
      },
    };
  },

  methods: {
    toggleMonthFilter(month) {
      if (!month) {
        this.onChangeFilterItem('months', [])
        return
      }

      const filterMonths = this.filter?.months || []
      const months = this.isActiveMonth(month)
        ? filterMonths?.filter(item => item !== month)
        : [...filterMonths, month]

      this.onChangeFilterItem('months', months)
    },

    onChangeFilterItem(name, value) {
      let filter = { ...this.filter }

      filter[name] = value

      if (name === 'region') {
        filter.organizer = ''
        this.$emit('region-change', true)
      }

      this.$emit('change', filter);
    },

    isActiveMonth(month) {
      return month ? this.filter?.months?.includes(month) : !this.filter?.months?.length
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/base/u-includes";

$b: '.u-events-filter';
$gup-into-list-x: 8px;
$gup-into-list-y: 16px;

$gup-month-list-y: 24px;

#{$b} {
  display: block;
  position: relative;
  z-index: 2;

  &__overflow {
    min-width: 100%;
    -ms-overflow-style: none;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
    box-sizing: border-box;

    @include tablet {
      margin-left: #{-$container-pd-left-tablet};
      margin-right: #{-$container-pd-right-tablet};
      padding-left: $container-pd-left-tablet;
      padding-right: $container-pd-right-tablet;
    }

    @include mobile {
      margin-left: #{-$container-pd-left-mobile};
      margin-right: #{-$container-pd-right-mobile};
      padding-left: $container-pd-left-mobile;
      padding-right: $container-pd-right-mobile;
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  &__top {
    margin-bottom: 41px;

    @include tablet {
      margin-bottom: 48px;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-flex;
    align-items: flex-end;
    min-width: 100%;

    &>li {

      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }


  &__item {
    cursor: pointer;
    display: block;
    position: relative;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.3;
    letter-spacing: 0.1em;
    color: rgba($color: $white-true, $alpha: 0.5);
    padding-bottom: 24px;
    white-space: nowrap;


    &::after {
      content: "";
      width: 100%;
      height: 4px;
      background-color: transparent;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &.is-active {
      color: $white-true;

      &::after {
        background-color: $color-base;
      }
    }
  }

  &__dropdowns {
    list-style: none;
    padding: 0;
    margin-top: -$gup-into-list-y;
    margin-bottom: -$gup-into-list-y;
    margin-left: -$gup-into-list-x;
    margin-right: -$gup-into-list-x;

    display: flex;
    flex-wrap: wrap;

    @include mobile {
      margin: 0;
    }
  }

  // .u-events-filter__dropdown-item
  &__dropdown-item{
    padding-top: $gup-into-list-y;
    padding-bottom: $gup-into-list-y;
    padding-left: $gup-into-list-x;
    padding-right: $gup-into-list-x;


    // .u-events-filter__dropdown-item--empty
    &--empty{
      @include tablet {
        display:none;
      }
    }

    &:nth-child(3n+1) ,
    &:nth-child(3n+2) {
      @include tablet-min {
        width: calc( (100% - (192px + ($gup-into-list-x * 2))) / 2);
      }

    }

    &:nth-child(3n+3){
      @include tablet-min {
        width: calc( 192px + ($gup-into-list-x * 2) );
      }
    }


    @include tablet {
      width: 50%;
    }

    @include mobile {
      width: 100%;
      padding: 0;

      &:not(:last-child){
        margin-bottom: 24px;
      }
    }
  }

  &__dropdown {
    select {
      width: 100%;
    }
  }

  &__month-list {
    position: relative;
    font-family: $font-family-main-head;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin-top: 64px;
    margin-bottom: -$gup-month-list-y;

    @include mobile {
      margin-top: 48px;
    }

    @include low-desktop {
      max-width: 650px;
    }

    &>li {
      margin-bottom: $gup-month-list-y;

      &:not(:last-child) {
        margin-right: 32px
      }
    }
    &:not(._without-first) {
      &>li {
        &:first-child {
          margin-right: 0;
          display: flex;
          align-items: center;

          &::after {
            content: "/";
            color: rgba($color-black, 0.5);
            margin: 0 24px;

          }
        }
      }
    }
  }

  &__month-item {
    cursor: pointer;
    user-select: none;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    color: $black-true;
    border-radius: 16px;
    transition: .2s ease;
    transition-property: color, background-color, padding;
    padding-top: 2px;

    &.is-active {
      padding-left: 8px;
      padding-right: 8px;
      background-color: $color-base;
      color: $white-true;
    }

    .inline-svg {
      width: 9px;
      height: 9px;
      margin: 0 0 1px 6px;
    }
  }
}
</style>
