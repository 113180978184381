<template>
  <div class="initiatives-themes">
    <div class="initiatives-themes__header" :class="`initiatives-themes__header--title-${titleSize}`">
      <h2 class="tt-uppercase initiatives-themes__title" :class="`title-${titleSize}`">
        {{ sectionTitle }}
      </h2>
      <img v-if="isExistsHeaderLogo" :src="getHeaderLogo" :alt="sectionTitle" class="initiatives-themes__header-logo">
    </div>
    <div class="initiatives-themes__items">
      <a
        v-for="theme in themes"
        :key="theme.id"
        class="initiatives-themes__items-block"
        :class="getCardClasses"
        :href="hrefWithRegion(theme.link)"
        @click="onClick(theme)"
      >
        <div
          class="initiatives-themes__item-head"
          :class="{'initiatives-themes__item-head--without-category': !category}"
        >
          <div>{{ category }}</div>
          <img
            v-if="theme.icon"
            class="initiatives-themes__item-icon"
            :src="theme.icon"
            :alt="theme.title"
          >
        </div>
        <div class="initiatives-themes__item-content">
          <div
            class="initiatives-themes__item-title tt-uppercase"
            :class="`initiatives-themes__item-title--${titleCardSize} title-${titleCardSize}`"
            v-html="theme.title"
          />
        </div>
        <div class="initiatives-themes__item-footer">
          <CustomButton class="initiatives-themes__item-btn" >
            Узнать больше
          </CustomButton>
          <img
            v-if="theme.icon && withLogoMob"
            class="initiatives-themes__item-icon-mob"
            :src="theme.icon"
            :alt="theme.title"
          >
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['breakpoint', 'ymHandler'],
  name: 'InitiativesThemes',
  props: {
    themes: {
      type: Array,
      default: () => []
    },
    sectionTitle: {
      type: String,
      default: 'Научные темы площадок'
    },
    category: {
      type: String,
      default: 'Тема'
    },
    titleSize: {
      type: String,
      default: 'xl',
    },
    titleCardSize: {
      type: String,
      default: 'lg',
    },
    isSmallMob: {
      type: Boolean,
      default: false
    },
    withLogoMob: {
      type: Boolean,
      default: false
    },
    headerLogo: {
      type: Object,
      default: () => ({})
    },
    ymData: {
      type: Object,
      default: () => ({})
    },
    ymParamKey: {
      type: String,
      default: ''
    }
  },
  computed: {
    getCardClasses() {
      const classes = []
      const prefix = 'initiatives-themes__items-block--'

      if (this.isSmallMob) {
        classes.push(`${prefix}is-small-mob`)
      }
      if (this.withLogoMob) {
        classes.push(`${prefix}with-logo-mob`)
      }

      return classes.join(' ')
    },
    isExistsHeaderLogo() {
      return this.headerLogo && Object.values(this.headerLogo).length
    },
    getHeaderLogo() {
      return this.breakpoint.isMobile ? this.headerLogo.mobile : this.headerLogo.desktop
    },
  },
  methods: {
    onClick(theme) {
      if (this.ymData && Object.values(this.ymData).length) {
        this.ymSend(theme)
      }
    },
    ymSend(theme) {
      const options = this.ymData.options || {}

      if (this.ymParamKey) {
        options[this.ymParamKey] = theme.title
      }

      const ymData = {...this.ymData, options}
      this.ymHandler(ymData)
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: ".initiatives-themes";

#{$b} {

  // .initiatives-themes__header
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    // .initiatives-themes__header--title
    &--title {
      &-xl {
        margin: 0 0 32px 0;

        @include mobile {
          margin-bottom: 24px;
        }
      }

      &-2xl {
        margin: 0 0 40px 0;

        @include low-desktop {
          margin-bottom: 32px;
        }
        @include mobile {
          margin-bottom: 24px;
        }
      }
    }

    // .initiatives-themes__header-logo
    &-logo {
      margin-left: 20px;
      width: 100%;
      max-width: 161px;
      height: auto;
      object-fit: contain;
      object-position: top right;

      @include mobile {
        max-width: 100px;
      }
    }
  }

  // .initiatives-themes__title
  &__title {
    margin: 0;
  }

  &__items {
    display: flex;
    margin: 0 -8px;
    flex-wrap: wrap;

    @include mobile {
      margin: 0;
      position: relative;
      z-index: 1;
    }

    &-block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 24px;
      width: calc(100% / 3 - 16px);
      margin: 16px 8px 0;
      border-radius: 16px;
      color: $white-true;
      min-height: 400px;
      text-decoration: none;
      transition: $transtion-default;

      &:hover {
        @include mobile-min {
          transform: scale(1.01)
        }
      }

      @include low-desktop {
        width: calc(50% - 16px);
      }

      @include mobile {
        width: 100%;
        margin: 16px 0 0;
        min-height: 259px;
      }

      &--is-small-mob {
        @include mobile {
          min-height: unset;
        }
      }

      &:nth-child(even) {
        background: rgba(0, 61, 255, 0.9);
      }

      &:nth-child(odd) {
        background: linear-gradient(225deg, #3706E2 0%, #080121 79.86%, #03000E 100%);
      }
    }
  }

  &__item {
    &-head {
      display: flex;
      justify-content: space-between;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-bottom: auto;
      padding-bottom: 15px;

      &--without-category {
        @include mobile {
          display: none;
        }
      }
    }

    &-btn {
      width: max-content;
    }

    &-content {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-top: auto;
      margin-bottom: 24px;
    }

    &-title {
      font-family: $font-family-main-head;


      &--md {
        @include mobile {
          font-size: 18px;
        }
      }
    }

    &-icon {
      min-width: 50px;
      min-height: 50px;
      max-height: 70px;
      max-width: 70px;

      @include mobile {
        display: none;
      }

      &-mob {
        display: none;

        @include mobile {
          #{$b}__items-block--with-logo-mob & {
            display: block;
            width: auto;
            max-width: 40px;
            max-height: 40px;
            height: 100%;
            margin: 0 0 0 auto;
          }
        }
      }
    }

    &-footer {

      @include mobile {
        #{$b}__items-block--with-logo-mob & {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
        }
      }
    }
  }
}
</style>
