<template>
  <component
    :is="isLinkCard ? 'a' : 'div'"
    v-bind="isLinkCard ? { href: hrefWithRegion(card.url) } : {}"
    class="u-events-card"
    :class="{ 'u-events-card--not-link' : !isLinkCard }"
  >
    <div v-if="card.image" class="u-events-card__media">
      <div
        class="u-events-card__image"
        :style="{ backgroundImage: `url('${card.image}')` }"
      />
    </div>
    <div class="u-events-card__body">
      <div class="u-events-card__subinfo">
        <div v-if="card.dateText?.length || periodItems?.length || card.isAdditional || (card.isRegional && card.id !== 4828) || card.type" class="u-events-card__other">
          <div v-if="card.dateText?.length || periodItems?.length || card.isAdditional || (card.isRegional && card.id !== 4828)" class="u-events-card__date">
            <div v-if="card.dateText?.length || periodItems?.length" class="u-events-card__date-label tt-uppercase">
              <span v-if="card.dateText?.length" v-html="card.dateText" />
              <div v-else-if="periodItems?.length">
                <span v-for="(item, i) in periodItems" :key="i">
                  <span v-if="i === 1" class="separator"> — </span>
                  {{ item }}
                </span>
              </div>
            </div>
            <div class="u-events-card__additional" v-if="card.isAdditional">дополнительное мероприятие</div>
            <div class="u-events-card__regional" v-if="card.isRegional && card.id !== 4828">региональное мероприятие</div>
          </div>
          <div v-if="card.type" class="u-events-card__type tt-uppercase" v-html="card.type" />
        </div>
        <div class="u-events-card__organizers" v-if="organizersString?.length">
          <span>При поддержке: </span>
          <span v-html="organizersString" />
        </div>
      </div>
      <div class="u-events-card__info">
        <h3 class="u-events-card__title tt-uppercase" v-html="card.title" />
        <p
          v-if="card.text?.length"
          class="u-events-card__description"
          v-html="card.text"
        />
        <div class="u-events-card__footer">
          <div class="u-events-card__place">
            <span v-html="card.place" />
          </div>
          <CustomButton
            v-if="!isHiddenBtn && !isEditable"
            class="u-events-card__show-more"
            tag="a"
            :href="hrefWithRegion(card.url)"
            theme="outline-inherit"
          >
            Узнать больше
          </CustomButton>
        </div>
      </div>
    </div>
    <EditingCard
      v-if="isEditable"
      @edit="$emit('edit', card.id)"
      @remove="$emit('remove', card.id)"
    />
  </component>
</template>

<script>
import moment from "moment";

export default {
  props: {
    card: {
      type: Object,
      default: () => ({})
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    isHiddenBtn: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isLinkCard() {
      return !this.isHiddenBtn && !this.isEditable
    },

    organizersString() {
      return this.card?.organizers?.map(item => item.name).join(', ') || null
    },

    periodItems() {
      const from = this.card.dateFrom
      const to = this.card.dateTo
      const onlyMonths = this.card.onlyMonths
      const dateFrom = moment(from);
      const dateTo = moment(to);

      if (!dateFrom.isValid() || !dateTo.isValid()) {
        return
      }

      if (onlyMonths) {
        return this.periodItemsOnlyMonths(dateFrom, dateTo);
      }

      let periods = [];
      if (dateFrom.isSame(dateTo)) {
        periods.push(dateFrom.format('DD MMMM'));
      } else {
        if (dateFrom.isSame(dateTo, 'year')) {
          if (dateFrom.isSame(dateTo, 'month')) {
            periods.push(dateFrom.format('DD'), dateTo.format('DD MMMM'));
          } else {
            periods.push(dateFrom.format('DD MMMM'), dateTo.format('DD MMMM'));
          }
        } else {
          periods.push(dateFrom.format('YYYY'), dateTo.format('YYYY'));
        }
      }

      return periods;
    }
  },

  methods: {
    periodItemsOnlyMonths(dateFrom, dateTo) {
      let periods = [];

      if (dateFrom.isSame(dateTo)) {
        periods.push(dateFrom.format('MMMM'));
      } else {
        if (dateFrom.isSame(dateTo, 'year')) {
          if (dateFrom.isSame(dateTo, 'month')) {
            periods.push(dateTo.format('MMMM'));
          } else {
            periods.push(dateFrom.format('MMMM'), dateTo.format('MMMM'));
          }
        } else {
          periods.push(dateFrom.format('MMMM YYYY'), dateTo.format('MMMM YYYY'));
        }
      }

      return periods;
    },
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.u-events-card';

#{$b} {
  position: relative;
  z-index: 0;
  min-height: 400px;
  height: 100%;
  display: flex;
  text-decoration: none;
  background-color: rgba($color: $white-true, $alpha: 0.8);
  transition: .2s ease;
  transition-property: transform,box-shadow;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  color: $black-true;

  &:hover,
  &:focus {
    @include mobile-min {
      transform: scale(1.01);
      box-shadow: $shadow-default-hover;
    }
  }

  &--not-link {
    cursor: default;
  }

  @include mobile {
    height: auto;
    flex-direction: column;
  }

  &__body {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: 24px;
  }

  &__subinfo {
    margin-bottom: 16px;
  }

  &__info {
    margin-top: auto;
  }

  &__other {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  &__date {
    margin-right: 10px;
  }

  &__date-label,
  &__type {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: inherit;
    letter-spacing: 0.1em;
  }

  &__additional,
  &__regional {
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-top: 20px;
  }

  &__organizers {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.3;
    color: inherit;
  }

  &__title {
    display: block;
    word-break: break-word;
    font-family: $font-family-main-head;
    font-weight: 500;
    font-size: 28px;
    line-height: 1;
    margin: 0;
  }

  &__description {
    margin: 16px 0 0 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;

    @include mobile {
      display: block;
      margin-top: 16px;
    }
  }

  &__place {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.3;

    @include mobile {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    @include mobile-min {
      max-width: calc(100% - 160px);
    }
  }

  &__media {
    background-color: #eee;
    flex-shrink: 0;

    @include mobile {
      order: -1;
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
    @include bg-cover;

    @include mobile {
      padding-top: 56%;
    }
  }
}
</style>
