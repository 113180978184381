<template>
  <div class="u-events-list">
    <ul class="u-events-list__list">
      <li v-if="isEditable">
        <AddendumCard class="u-events-list__addendum-card" @click="$emit('addNewEvent')">
          Добавить Проекты <br> и мероприятия
        </AddendumCard>
      </li>
      <li
        v-for="(item, i) in items"
        :key="i"
        :class="{
          '_additional': item.isAdditional,
          '_regional': item.isRegional,
        }"
      >
        <UEventsCard
          :isEditable="isEditable"
          :isHiddenBtn="isHiddenBtn"
          :card="item"
          @edit="(v) => $emit('edit', v)"
          @remove="(v) => $emit('remove', v)"
        />
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  props: {
    items: {
      type: Array,
      require: true
    },
    isHiddenBtn: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    isLinks: {
      type: Boolean,
      default: false
    },
  },
}
</script>

<style lang="scss">
@import "@/scss/base/u-includes";

$b: '.u-events-list';

@mixin additionalFlex {
  @include desktop-min {
    display: flex;
    align-items: center;
    justify-content: space-between;

    #{$b}__item-additional {
      text-align: right;
      max-width: 170px;
      margin-top: 0;
    }
  }
}

#{$b} {

  &__list {
    list-style: none;
    padding: 0;
    margin: 0 0 0 0;
    display: flex;
    flex-wrap: wrap;

    @include mobile-min {
      margin-left: -16px;
      margin-bottom: -16px;
    }

    & > li {
      width: calc(100% / 3);

      @include mobile-min {
        padding-left: 16px;
        margin-bottom: 16px;
      }

      @include low-desktop {
        width: 50%;
      }

      @include mobile {
        width: 100%;
        padding-left: 0;

        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }

      &:nth-child(3n + 2) {

        @include low-desktop-min {
          width: calc(100% / 3 * 2);
        }

        @include desktop-min {
          .u-events-card__regional {
            margin-top: 0;
          }
        }

        &._additional,
        &._regional {
          .u-events-card__date {
            width: 100%;
            @include additionalFlex;
          }
        }
      }

      &:nth-child(6n + 3),
      &:nth-child(6n + 4) {
        @include low-desktop-min {
          width: 50%;
        }

        .u-events-card__media {
          @include mobile-min {
            @include full-abs;
            z-index: -1;

            &:before {
              content: "";
              @include full-abs;
            }
          }
        }
      }

      &:nth-child(6n + 2),
      &:nth-child(6n),
      &:nth-child(6n + 3) {
        .u-events-card {
          background-color: $color-base;
          color: $white-true;
          border-color: $white-true;
        }
      }

      &:nth-child(6n + 1),
      &:nth-child(6n + 6) {
        .u-events-card__media {
          @include mobile-min {
            display: none;
          }
        }
      }


      &:nth-child(3n + 2) {
        .u-events-card__media {
          border-radius: inherit;
          overflow: hidden;

          @include low-desktop-min {
            display: block;
            width: 296px;
          }
        }
      }

      &:nth-child(6n + 3) {
        .u-events-card__media {
          @include mobile-min {
            &:before {
              background-color: rgba($color-base-origin, 0.6)
            }
          }
        }
      }

      &:nth-child(6n + 4) {
        .u-events-card__media {
          @include mobile-min {
            &:before {
              background-color: rgba($white-true, 0.8);
            }
          }
        }
      }
    }
  }

  // .u-events-list__addendum-card
  &__addendum-card {
    min-height: 376px;
  }
}
</style>
